var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-dialog',{staticClass:"ma-0 pa-0",attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-btn",attrs:{"outlined":"","height":"80","block":""}},'v-btn',attrs,false),on),[_vm._v(" EXAME FUNDO DE OLHO "),_c('v-icon',{staticClass:"ma-3",attrs:{"small":"","color":"white","size":"60"}},[_vm._v(" mdi-list-status ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-8 ma-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right"},[_c('v-icon',{on:{"click":function($event){_vm.dialog=false}}},[_vm._v("mdi-close")])],1)],1),_c('v-card-title',[_vm._v(" EXAME FUNDO DE OLHO ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.pacientes,"headers":_vm.headers,"loading":_vm.loading,"search":_vm.search,"hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.chamar",fn:function(ref){
var item = ref.item;
return [_c('ExameFundoOlho',{attrs:{"current-item":item},on:{"atualiza":function($event){return _vm.get_listas('mutirao-sus/pacientes/?etapa=biomarcador', 'pacientes')}}})]}},{key:"item.dt_nascimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcularIdade(item.dt_nascimento))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }